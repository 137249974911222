import _ from 'lodash';
import './style.css';
import WebGL from './WEBGL.js';

import CANNON from 'cannon'

import System, {
    Emitter,
    Rate,
    Span,
    Position,
    BoxZone,
    Mass,
    Radius,
    Life,
    Body,
    RadialVelocity,
    PointZone,
    Vector3D,
    Alpha,
    Scale,
    Color,
    SpriteRenderer,
    Rotate,
    RandomDrift,
    Gravity,
  } from 'three-nebula';

import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js'

const USE_COMPOSER = true;

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}

const DOT_TEXTURE = 'assets/textures/dot.png';
const SNOWFLAKE_TEXTURE = 'assets/textures/snowflake.png';

const createSprite = (textureFilename) => {
    const map = new THREE.TextureLoader().load(textureFilename);
    const material = new THREE.SpriteMaterial({
      map: map,
      color: 0xff0000,
      blending: THREE.AdditiveBlending,
      fog: true,
    });
 
    return new THREE.Sprite(material);
  };

function createEnum(values) {
const enumObject = {};
for (const val of values) {
    enumObject[val] = val;
}
return Object.freeze(enumObject);
}
  
const STATE = createEnum(['starting', 'main', 'balls', 'backside_transition', 'backside', 'frontside_transition']);

let state = STATE.starting;

// List of door positions
var doorPositions = Object.seal([
    [-0.17707545876437553, 0.18743074875059626],
    [0.1252990088332715, -0.1299588893270709],
    [-0.1356542988194923, 0.07196926540423447],
    [0.022263873470374722, 0.15015170480020146],
    [-0.14134970831191376, -0.03831457294901694],
    [0.29409023560867037, -0.028477047462107143],
    [0.21642556071201446, 0.22315649920305805],
    [-0.2148722672140814, 0.10096407736565272],
    [-0.11753254134360597, 0.17086228477264304],
    [0.12374571533533849, 0.06264950441663578],
    [0.22833414419616843, 0.28943035511487114],
    [0.19467945174095083, 0.0906087873794319],
    [-0.22108544120581386, -0.006213173991732447],
    [0.0999285483670306, 0.1610247592857333],
    [0.12840559582913774, -0.04142115994488313],
    [-0.21073015121959304, -0.09785749036978648],
    [-0.21383673821545932, 0.30548105459351327],
    [0.00724870299035453, -0.32308504757008855],
    [0.15895370128848912, 0.18587745525266317],
    [-0.2795928296279614, -0.13410100532155925],
    [-0.12995888932707092, -0.13099441832569297],
    [-0.2692375396417405, -0.25940001415483077],
    [-0.04245668894350524, 0.15429382079468973],
    [-0.001553293497933108, -0.05798962392283647]]);

   
// Get closest index in an array of positions to a given point
function getClosestDoor(point, list) {
    // Create a new Three js vector 2 from the point
    let p = new THREE.Vector2(point[0], point[1]);

    let closestIndex = 0;
    let closest = new THREE.Vector2(list[closestIndex][0], list[closestIndex][1]);
    let closestDist = p.distanceTo(closest);
    for (let i = 1; i < list.length; i++) {
        let current = new THREE.Vector2(list[i][0], list[i][1]);
        let dist = p.distanceTo(current);
        if (dist < closestDist) {
            closestIndex = i;
            closest = current;
            closestDist = dist;
        }
    }
    return [closestIndex, closestDist];
}

// Function that raycasts from a three.js vector2 screen position
function raycastFromScreenPosition(screenPosition, camera, scene) {
    // Create a raycaster
    let raycaster = new THREE.Raycaster();

    // Set the raycaster's position from the screen position
    raycaster.setFromCamera(screenPosition, camera);

    // Get the raycaster's intersects
    let intersects = raycaster.intersectObjects(scene.children, true);    

    // Return the first intersected object
     if (intersects.length > 0) {
        return intersects[0];
     } else {
         return null;
     }
}

let ballSpawnerInterval = null; 

function openDoor(doorIndex) {
    if( state === STATE.balls){
        clearBalls();
        state = STATE.main;
        if (ballSpawnerInterval != null) {
            clearInterval(ballSpawnerInterval);
        }
    }

    if( doorIndex == 2) {
        snowEmitterEnabled = !snowEmitterEnabled;
        if( snowEmitterEnabled ) {
            snowEmitter.emit();
        } else {
            snowEmitter.stopEmit()
        }
    } else if ( doorIndex == 12) {

        // Create the backside of the calendar and hide the object
        if( calendarBackside == null){
            calendarBackside = createCalendarBackside();
            calendarBackside.visible = true;    
            scene.add(calendarBackside);
        }

        // Start the transition to the backside
        state = STATE.backside_transition;
    } else if ( doorIndex == 22) {

        state = STATE.balls;

        ballSpawnerInterval = setInterval(() => { 

            // Get a random int between 0 and 3
            let randomInt = Math.floor(Math.random() * 3);
            let color = 0xffff00
            switch (randomInt) {
                case 0:
                    color = 0xffff00;
                    break;
                case 1:
                    color = 0xff00ff;
                    break;
                case 2:
                    color = 0x00ffff;
                    break;
                default:
                    break;
            }

            let sphere = createPhysicsBall(-0.01 + Math.random()*.01, 1.5+Math.random()*3, 0.1, 0.04, 1);
            physicsBalls.push(sphere);
            let sphereMesh =  new THREE.Mesh( sphereGeometry, new THREE.MeshBasicMaterial( { color: color } ) );
            scene.add(sphereMesh);
            balls.push(sphereMesh);

            if (balls.length > 40) {
                clearInterval(ballSpawnerInterval);
                ballSpawnerInterval = null;
            }

        }, 1000);

        // Create three spheres and add physics to them
        let sphere = createPhysicsBall(-0.28,- 0.2, 0.08, 0.04, 1);
        let sphere2 = createPhysicsBall(0.01, 0.5, 0.08, 0.04, 1);
        let sphere3 = createPhysicsBall(-0.01, 1.5, 0.08, 0.04, 1);
    
        physicsBalls.push(sphere);
        physicsBalls.push(sphere2);
        physicsBalls.push(sphere3);
    
        const sphereGeometry = new THREE.SphereGeometry( 0.04, 16, 8 );
    
        let sphereMesh =  new THREE.Mesh( sphereGeometry, new THREE.MeshBasicMaterial( { color: 0xffff00 } ) );
        let sphereMesh2 =  new THREE.Mesh( sphereGeometry, new THREE.MeshBasicMaterial( { color: 0xff00ff } ) );
        let sphereMesh3 =  new THREE.Mesh( sphereGeometry, new THREE.MeshBasicMaterial( { color: 0x00ffff } ) );
        scene.add(sphereMesh);
        scene.add(sphereMesh2);
        scene.add(sphereMesh3);
        balls.push(sphereMesh);
        balls.push(sphereMesh2);
        balls.push(sphereMesh3);
    

    } else {
        // Navigate to the selected door sketch
        window.location.href = "/assets/sketches/" + doorIndex + "/index.html";
    }
}


function createCalendarPlane() {

    // Create texture material
    let imgTexture = new THREE.TextureLoader().load('assets/textures/advent_calendar.png');
    imgTexture.wrapS = imgTexture.wrapT = THREE.RepeatWrapping;
    imgTexture.encoding = THREE.sRGBEncoding;
    imgTexture.anisotropy = 16;

    const normalMap = new THREE.TextureLoader().load( 'assets/textures/NormalMap.png' );

    const specularMap = new THREE.TextureLoader().load( 'assets/textures/SpecularMap.png' );
    specularMap.encoding = THREE.sRGBEncoding;

    calendarMaterial = new THREE.MeshPhongMaterial( {
        emissive: 0x000000,
        color: 0xdddddd,
        specular: 0x666666,
        shininess: 35,
        map: imgTexture,
        specularMap: specularMap,
        normalMap: normalMap,
        normalScale: new THREE.Vector2( 0.9, 0.9 )
    } );

    // Create the plane geometry
    const geometry = new THREE.PlaneGeometry(1, 1);

    // Create and return a mesh object
    return new THREE.Mesh(geometry, calendarMaterial);
}

function createCalendarBackside() {

    // Create texture material
    let imgTexture = new THREE.TextureLoader().load('assets/textures/backside.jpg');
    imgTexture.wrapS = imgTexture.wrapT = THREE.RepeatWrapping;
    imgTexture.encoding = THREE.sRGBEncoding;
    imgTexture.anisotropy = 16;


    let backsideMaterial = new THREE.MeshStandardMaterial( {
        emissive: 0x000000,
        color: 0xdddddd,
        map: imgTexture,
        side: THREE.BackSide
    } );

    // Create the plane geometry
    const geometry = new THREE.PlaneGeometry(1, 1);

    // Create and return a mesh object
    return new THREE.Mesh(geometry, backsideMaterial);
}

// Get screen position from client position
function getScreenPosition(clientX, clientY) {
    let pointer = new THREE.Vector2();
    pointer.x = (clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (clientY / window.innerHeight) * 2 + 1;
    return pointer;
}

// Function process touch end events
function onTouchEnd(event) {
    event.preventDefault();

    let touch = event.changedTouches[0];

    let x = touch.clientX;
    let y = touch.clientY;

    onPointerUp(x,y);
}

// Function to process touch start events
function onTouchStart(event) {
    event.preventDefault();

    let touch = event.changedTouches[0];

    let x = touch.clientX;
    let y = touch.clientY;

    onPointerDown(x,y);
}

function onMouseUp(event) { 
    event.preventDefault();
    onPointerUp(event.clientX, event.clientY)
}

function onMouseDown(event) {
    event.preventDefault();
    onPointerDown(event.clientX, event.clientY);
}

let lastPointerDownTime = 0;

function onPointerDown(x, y) {
    lastPointerDownTime = new Date().getTime();
}

function onPointerUp(x,y) {
    let currentTime = new Date().getTime();
    if (currentTime - lastPointerDownTime < 200) {
        if( state === STATE.backside ) {
            state = STATE.frontside_transition;
            return;
        }    
        onClick(x,y);
    }
}

// Function to process mouse up events
function onClick(x, y) {

    // debug log
    console.log("click x: " + x + " y: " + y);

    //  Get intersection point
    let pointer = getScreenPosition(x, y);
    let intersection = raycastFromScreenPosition(pointer, camera, scene);

    //  If there is an intersection, get closest door
    if (intersection) {
        if (intersection.object != calendarPlane) {
            return;
        }

        // log intersection point
        // console.log("[" + intersection.point.x + ", " + intersection.point.y + "],");
        
        let res = getClosestDoor([intersection.point.x, intersection.point.y], doorPositions);
        if (res[1] < 0.061) {

            var today = new Date();
            var dd = String(today.getDate());
            var mm = String(today.getMonth() + 1);

            let doorIndex = res[0] + 1
            if (process.env.NODE_ENV == 'production') {
                if (mm == 12 && dd >= doorIndex) {
                    console.log("open door " + (doorIndex));
                    prepareForDoorOpen(doorIndex, intersection.point);
                }
            } else {
                console.log("open door " + doorIndex);
               
                prepareForDoorOpen(doorIndex, intersection.point);

                // Set helper position
                helper.position.copy( intersection.point );

            }

        }
    }
}

function prepareForDoorOpen(doorIndex, intersectionPoint){

    // Set the door index
    doorToOpen = doorIndex;

    // Set camera target positions for door animation
    cameraTargetPosition = new THREE.Vector3(intersectionPoint.x, intersectionPoint.y, intersectionPoint.z);
    cameraReturnPosition = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);
    cameraLookAtPosition = new THREE.Vector3(camera.lookAt.x, camera.lookAt.y, camera.lookAt.z);

    fadeToWhite = !fadeToWhite;
}

let world, mass, body, shape, timeStep=1/60;

function initCannon() {

    world = new CANNON.World();
    world.gravity.set(0, -1.0, 0)
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 10;

    // Create a ground plane
    const planeShape = new CANNON.Plane()
    const planeBody = new CANNON.Body({ mass: 0 })
    planeBody.addShape(planeShape)
    planeBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
    planeBody.position.y = -.5;
    world.addBody(planeBody)

    const calendarShape = new CANNON.Plane()
    const calendarBody = new CANNON.Body({ mass: 0 })
    calendarBody.addShape(calendarShape)
//    calendarBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
    world.addBody(calendarBody)

}

function clearBalls(){
    console.log("clear balls");
    for( let i = 0; i < physicsBalls.length; i++ ) {
        world.removeBody(physicsBalls[i]);
        scene.remove(balls[i]);
    }

    physicsBalls = [];
    balls = [];
}

function createPhysicsBall(x, y, z, radius, mass) {
    let shape = new CANNON.Sphere(radius);
    let body = new CANNON.Body({ mass: mass });
    body.addShape(shape);
    body.position.set(x, y, z);
    body.angularVelocity.set(0,0,0);
    body.angularDamping = 0.5;
    world.addBody(body);

    return body;
}

/// ThreeJS objects
let scene, camera, renderer, calendarPlane, calendarBackside, helper;
let calendarMaterial;
let light1, light2;
let sparkle;

let mesh;

let balls = []
let physicsBalls = []

// Camera target variables used for door opening animation
let cameraTargetPosition = null;
let cameraReturnPosition = null;
let cameraLookAtPosition = null;
let fadeToWhite = false;

let doorToOpen = null;

let snowEmitter;
let snowEmitterEnabled = false;

// Bloom post processing parameters
const bloomParams = {
    exposure: 1.1,
    bloomStrength: 0.02,
    bloomThreshold: 0.1,
    bloomRadius: 0.1
};

function setupThreeScene() {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = .5;

    renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    // Create a plane for our calendar a texture material
    calendarPlane = createCalendarPlane();
    scene.add(calendarPlane);

    const sphereHelper = new THREE.SphereGeometry( 0.01, 16, 8 );
    sphereHelper.translate( 0, 0, 0 );
    helper = new THREE.Mesh( sphereHelper, new THREE.MeshNormalMaterial() );

    // Create lights
    const sphere = new THREE.SphereGeometry( 0.001, 16, 8 );

    light1 = new THREE.PointLight( 0xffffff, 2, 5 );
    scene.add( light1 );

    light2 = new THREE.PointLight( 0x0040ff, 1, 5 );
    scene.add( light2 );

    sparkle = new THREE.PointLight( 0xffff00, 2, 5 );
    sparkle.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xffff00 } ) ) );
    scene.add( sparkle );

    // Init physics engine
    initCannon();

    // Create a particle system
    const particleSystem = new System();
    const particleEmitter = new Emitter();
    snowEmitter = new Emitter();
     const particelRenderer = new SpriteRenderer(scene, THREE);
    
    // Set emitter rate (particles per second) as well as the particle initializers and behaviours
    particleEmitter
    .setRate(new Rate(new Span(2, 3), new Span(0.01, 0.02)))
    .setInitializers([
        new Position(new PointZone(0, 0)),
        new Mass(0.1),
        new Radius(0.01, 0.05),
        new Life(2),
        new Body(createSprite(DOT_TEXTURE)),
        new RadialVelocity(0.07, new Vector3D(0, 1, 0), 360),
    ])
    .setBehaviours([
        new Alpha(1, 0),
        new Scale(0.2, 0.5),
        new Color(new THREE.Color(0xffff00), new THREE.Color()),
    ])
    .setPosition( {x:0, y:0, z:0} )
    .emit();

    // Set emitter rate (particles per second) as well as the particle initializers and behaviours
    snowEmitter
    .setRate(new Rate(new Span(2, 3), new Span(0.01, 0.02)))
    .setInitializers([
        new Mass(1),
        new Radius(new Span(0.1, 0.2)),
        new Position(new BoxZone(1, 1, 1)),
        new Life(5, 10),
        new RadialVelocity(0, new Vector3D(0, -1, 0), 90),
        new Radius(0.001, 0.005),
        new Body(createSprite(SNOWFLAKE_TEXTURE)),
    ])
    .setBehaviours([
        new RandomDrift(0.01, 0.01, 0.01, 0.0005),
        new Rotate('random'),
        new Gravity(0.0005),
        new Alpha(1, 0),
        new Scale(0.2, 0.5),
        new Color(new THREE.Color(0xffffff), new THREE.Color()),
    ])
    .setPosition( {x:0, y:0.8, z:0} )
    
    // add the emitter and a renderer to your particle system
    particleSystem
    .addEmitter(particleEmitter)
    .addEmitter(snowEmitter)
    .addRenderer(particelRenderer);

    let composer = null;

    if ( USE_COMPOSER ) {
        // Add post-processing
        composer = new EffectComposer(renderer);
        composer.addPass(new RenderPass(scene, camera));

        const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        bloomPass.threshold = bloomParams.bloomThreshold;
        bloomPass.strength = bloomParams.bloomStrength;
        bloomPass.radius = bloomParams.bloomRadius;

        composer.addPass(bloomPass);
    }

    // Handle onResize event
    window.onresize = function () {
        const width = window.innerWidth;
		const height = window.innerHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
        
        if ( USE_COMPOSER ) {
            composer.setSize(width, height);
        }
    };

    // add event listener to window on mouse up
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('mousedown', onMouseDown, false);
    window.addEventListener('touchend', onTouchEnd, false);
    window.addEventListener('touchstart', onTouchStart, false);

    // Orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.minDistance = 0.2;
    controls.maxDistance = 1;
    controls.maxPolarAngle = 7*Math.PI/12;
    controls.minPolarAngle = 5*Math.PI/12;
    controls.maxAzimuthAngle = Math.PI/12;
    controls.minAzimuthAngle = -Math.PI/12;

    calendarMaterial.color = new THREE.Color(0x000000);
    calendarMaterial.specular = new THREE.Color(0x000000);
    let targetColor = new THREE.Color(0xdddddd);
    let targetSpecular = new THREE.Color(0x666666);
	const clock = new THREE.Clock();

    function handleOpenDoorAnimation() {
        // If there's a target position, move the camera to it
        if (cameraTargetPosition) {
            camera.position.lerp(cameraTargetPosition, 0.02);
            cameraLookAtPosition.lerp(cameraTargetPosition, 0.03);
            camera.lookAt(cameraLookAtPosition);

            if (fadeToWhite) { 
                // lerp towards white
                calendarMaterial.emissive.lerp(new THREE.Color(0xffffff), 0.06);
            } else {
                // lerp towards black
                calendarMaterial.color.lerp(new THREE.Color(0x000000), 0.06);
                calendarMaterial.specular.lerp(new THREE.Color(0x000000), 0.06);

            }
            
            if (camera.position.distanceTo(cameraTargetPosition) < 0.2) {
                cameraTargetPosition = null;

                openDoor(doorToOpen);
            }
        } else {
            if (cameraReturnPosition) {
                camera.position.lerp(cameraReturnPosition, 0.02);
                cameraLookAtPosition.lerp(new THREE.Vector3(0,0,0), 0.03);
                camera.lookAt(cameraLookAtPosition);

                if (fadeToWhite) { 
                    // lerp from white
                    calendarMaterial.emissive.lerp(new THREE.Color(0x000000), 0.06);
                } else {
                    // lerp from black
                    calendarMaterial.color.lerp(new THREE.Color(0xdddddd), 0.06);
                    calendarMaterial.specular.lerp(new THREE.Color(0x666666), 0.06);
                }

                if (camera.position.distanceTo(cameraReturnPosition) < 0.01) {
                    cameraReturnPosition = null;
                }    
            } else {
                // Update orbit controls
                controls.update();
            }
        }
    }

    // Render/Animation loop
    function animate() {
        requestAnimationFrame(animate);

        updatePhysics();

        particleEmitter.setPosition( sparkle.position );
        particleSystem.update();

        handleOpenDoorAnimation()

        const delta = clock.getDelta();

        if( state === STATE.backside_transition ) {
            // rotate calendar
            calendarPlane.rotation.y -= delta;
            calendarBackside.rotation.y -= delta;
            if (calendarPlane.rotation.y < -Math.PI) {
                state = STATE.backside;
            }
        }
        if( state === STATE.frontside_transition ) {
            // rotate calendar
            calendarPlane.rotation.y -= delta;
            calendarBackside.rotation.y -= delta;
            if (calendarPlane.rotation.y <= -2*Math.PI) {
                calendarPlane.rotation.y = 0;
                calendarBackside.rotation.y = 0;
                state = STATE.main;
            }
        }
        
        //  Update light positions
        const time = Date.now() * 0.001;
        
        light1.position.x = Math.sin( time * 0.7 ) * 0.1;
        light1.position.y = Math.cos( time * 0.5 ) * 0.4;
        light1.position.z = 1+Math.cos( time * 0.3 ) * 0.3;

        light2.position.x = Math.cos( time * 0.3 ) * 0.3;
        light2.position.y = Math.sin( time * 0.5 ) * 0.4;
        light2.position.z = 1+Math.sin( time * 0.7 ) * 0.3;

        sparkle.position.x = Math.cos( time * 0.3 ) * 0.5;
        sparkle.position.y = Math.sin( time * 0.5 ) * 0.5;
        sparkle.position.z = Math.sin( time * 0.7 ) * 0.2;


        if (state === STATE.starting) {
            // lerp from black
            calendarMaterial.color.lerp(targetColor, delta);
            calendarMaterial.specular.lerp(targetSpecular, delta);
            if (calendarMaterial.color.equals(targetColor)) {
                state = STATE.main
            }
        }

        if ( USE_COMPOSER ) {
            composer.render(delta);
        } else {
            renderer.render(scene, camera);
        }
    }

    function updatePhysics() {
        // Step the physics world
        world.step(timeStep);
        // Copy coordinates from Cannon.js to Three.js
        for( let i = 0; i < physicsBalls.length; i++ ) {
            balls[i].position.copy(physicsBalls[i].position);
            balls[i].quaternion.copy(physicsBalls[i].quaternion);
        }
    }
    // Start the animation loop
    animate();
}

// check for webgl support, start scene if supported
if (WebGL.isWebGLAvailable()) {
    setupThreeScene();
} else {
    // WebGL is not supported, show error message
    const warning = WebGL.getWebGLErrorMessage();
    document.getElementById('container').appendChild(warning);
}
